<template>
  <div class="zui-car-list" @click="onClick">
    <img :src="src" @click="onClick"/>
    <div class="zui-buy-area">
      <div>
        <div class="sub-title" v-for="item in subTitle" :key="item">{{ $t(item) }}</div>
        <div class="title">{{ $t(title) }}</div>
      </div>
      <div class="button">
        <span class="zui-buy-button" @click="goToBuy(type)">{{ $t('car_list.buy_now') }}</span>
        <span class="zui-learn-button" @click="goToLearn(type)">{{ $t('car_list.learn_more') }}</span>
      </div>

    </div>
  </div>
</template>
<script>
import "./less/carlist.less"

export default {
  name: "CarList",
  props: {
    type: {
      default: "carlistM2"
    },
    href: {
      default: "/"
    }
  },
  computed: {
    src: function () {
      if (this.type === "carlistTHE5") {
        return "https://www.achencybe.com/img/pc/home/6-4.jpg";
      } else if (this.type === "carlistTHE4") {
        return "https://www.achencybe.com/img/pc/the4/4-0-1.jpg";
      } else if (this.type === "carlistM3") {
        return "https://www.achencybe.com/img/pc/home/6-1.jpg";
      } else if (this.type === "carlistBMW3") {
        return "https://www.achencybe.com/img/pc/home/6-2.jpg";
      } else if (this.type === "carlistchallenger") {
        return "https://www.achencybe.com/img/pc/home/6-3.jpg";
      } else if (this.type === "carlistX3") {
        return "https://www.achencybe.com/img/pc/x3/X3-0-1.jpg";
      } else {
        return null;
      }
    },
    title: function () {
      if (this.type === "carlistTHE5") {
        return "car_list.the5.slogan";
      } else if (this.type === "carlistTHE4") {
        return "car_list.the4.slogan";
      } else if (this.type === "carlistM3") {
        return "car_list.M3.slogan";
      } else if (this.type === "carlistBMW3") {
        return "car_list.the3.slogan";
      } else if (this.type === "carlistchallenger") {
        return "car_list.dodge.slogan";
      } else if (this.type === "carlistX3") {
        return "car_list.x3.slogan";
      } else {
        return null;
      }
    },
    subTitle: function () {
      if (this.type === "carlistTHE5") {
        return ["car_list.the5.suitable", "car_list.the5.style"];
      } else if (this.type === "carlistTHE4") {
        return ["car_list.the4.suitable", "car_list.the4.style"];
      } else if (this.type === "carlistM3") {
        return ["car_list.M3.suitable", "car_list.M3.style"];
      } else if (this.type === "carlistBMW3") {
        return ["car_list.the3.suitable", "car_list.the3.style"];
      } else if (this.type === "carlistchallenger") {
        return ["car_list.dodge.suitable", "car_list.dodge.style"];
      } else if (this.type === "carlistX3") {
        return ["car_list.x3.suitable", "car_list.x3.style"];
      } else {
        return null;
      }
    }
  },
  methods: {
    onClick: function (e) {
      if (this.type === "carlogo") {
        location.assign(this.href);
      }
    },
    goToBuy: function (types) {
      if(types === 'carlistX3'){
        this.$router.push({path: "/pages/public/TemplateOrderPage", query: {cartype: types}});
        return
      }
      this.$router.push({path: "/pages/public/OrderPage", query: {cartype: types}});
    },
    goToLearn: function (types) {
      if (types === "carlistTHE5") {
        this.$router.push({path: "/pages/the5/THE5ShowPage"});
      } else if (types === "carlistTHE4") {
        this.$router.push({path: "/pages/the4/THE4ShowPage"});
      } else if (types === "carlistBMW3") {
        this.$router.push({path: "/pages/bmw3/BMW3ShowPage"});
      } else if (types === "carlistM3") {
        this.$router.push({path: "/pages/m3/M3OrM4ShowPage"});
      } else if (types === "carlistX3") {
        this.$router.push({path: "/pages/x3/X3ShowPage"});
      } else if (types === "carlistchallenger") {
        this.$router.push({path: "/pages/challenger/ChallengerShowPage"});
      }
    }
  }
};
</script>

<style>
</style>
