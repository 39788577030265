var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "zui-car-list", on: { click: _vm.onClick } },
    [
      _c("img", { attrs: { src: _vm.src }, on: { click: _vm.onClick } }),
      _c("div", { staticClass: "zui-buy-area" }, [
        _c(
          "div",
          [
            _vm._l(_vm.subTitle, function (item) {
              return _c("div", { key: item, staticClass: "sub-title" }, [
                _vm._v(_vm._s(_vm.$t(item))),
              ])
            }),
            _c("div", { staticClass: "title" }, [
              _vm._v(_vm._s(_vm.$t(_vm.title))),
            ]),
          ],
          2
        ),
        _c("div", { staticClass: "button" }, [
          _c(
            "span",
            {
              staticClass: "zui-buy-button",
              on: {
                click: function ($event) {
                  return _vm.goToBuy(_vm.type)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("car_list.buy_now")))]
          ),
          _c(
            "span",
            {
              staticClass: "zui-learn-button",
              on: {
                click: function ($event) {
                  return _vm.goToLearn(_vm.type)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("car_list.learn_more")))]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }