var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "page",
    { staticClass: "home-page" },
    [
      _c("zui-header"),
      _c(
        "div",
        { staticClass: "service minus-top" },
        [
          _c("img", {
            staticStyle: { "max-width": "77%", margin: "0 auto 30px" },
            attrs: { src: _vm.banner, alt: "" },
          }),
          _c("zui-car-list", { attrs: { type: "carlistX3" } }),
          _c("zui-car-list", { attrs: { type: "carlistTHE4" } }),
          _c("zui-car-list", { attrs: { type: "carlistBMW3" } }),
          _c("zui-car-list", { attrs: { type: "carlistTHE5" } }),
          _c("zui-car-list", { attrs: { type: "carlistM3" } }),
          _c("zui-car-list", { attrs: { type: "carlistchallenger" } }),
        ],
        1
      ),
      _c("zui-footer"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }