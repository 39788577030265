<template>
  <page class="home-page">
    <zui-header />
    <div class="service minus-top">
      <img
        :src="banner"
        alt=""
        style="max-width: 77%; margin: 0 auto 30px"
      />
      <zui-car-list type="carlistX3"></zui-car-list>
      <zui-car-list type="carlistTHE4"></zui-car-list>
      <zui-car-list type="carlistBMW3"></zui-car-list>
      <zui-car-list type="carlistTHE5"></zui-car-list>
      <zui-car-list type="carlistM3"></zui-car-list>
      <zui-car-list type="carlistchallenger"></zui-car-list>
    </div>

    <!-- <div class="apply-hr">
      <h2>
        选择智慧互联，选择优质服务
      </h2>
      <h4>
        行业解决方案咨询和实施，提供多业务领域的信息化平台咨询、建设及运营托管服务
      </h4>
    </div> -->
    <zui-footer />
  </page>
</template>
<script>
import "./less/home-page.less";
import Page from "../components/Page";
import ZuiHeader from "../components/Header";
import ZuiFooter from "../components/Footer";
import ZuiCarList from "../components/CarList";
import Util from "../Util";

export default {
  components: { Page, ZuiHeader, ZuiFooter, ZuiCarList, },
  data() {
    return {
      banner: "",
    };
  },
  mounted() {
    this.getBanner();
  },
  methods: {
    getBanner() {
      this.$api.getBanner().then((res) => {
        if (res.code === 0) {
          let data = res.data.find((item) => {
            return item.pictureTpye == "0";
          });
          if (data) {
            this.banner = "https://www.achencybe.com/admin" + data.attachmentLocalAddress;
          }
        }
      });
    },
  },
};
</script>